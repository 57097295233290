.slider-container {
  width: 80vw;
  margin: 0 auto;
}
.slider-div {
  width: 60vw;

  .card {
    padding: 10px;
    margin: 0 10px;
    height: 70vh;
    display: flex;
    flex-direction: row;
    .card-content {
      background-color: #cc0000;
      width: 70%;
      color: white;
      padding: 20% 10%;
      p {
        color: white;
      }
    }
    .card-img {
      background-color: black;
      width: 30%;
    }
    .inner-div {
      background-color: green;
    }
  }
}

.filmstrip-container {
  background-color: white;
}

.slick-slide.slick-center img {
  margin: 0 auto;
  height: 80vh;
}

.slick-slide img {
  height: 80vh;
}

.slick-arrow {
  color: red;
  background-color: red;
}

.slick-prev:before {
  color: black !important;
}

.slick-next:before {
  color: black !important;
}

.filmstrip-card {
  height: 100%;
  display: flex;
  flex-direction: row;
  .card-content {
    background-color: #cc0000;
    width: 50%;
    color: white;
    padding: 10%;
    // padding: 20% 10%;
    p {
      color: white;
    }
  }
  .card-img {
    background-color: black;
    width: 50%;
    img {
      height: 100%;
      width: 100%;
    }
  }
}

.carousel-container {
  padding-bottom: 80px;
  background-color: white;
}

@media only screen and (max-width: 754px) {
  .filmstrip-card {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;

    .card-content {
      background-color: #cc0000;
      width: 100%;
      height: 252px;

      color: white;
      padding: 10%;
    }
    .card-img {
      background-color: black;
      width: 100%;

      img {
        height: 100%;
        width: 100%;
      }
    }
  }

  .carousel-box {
    width: 90vw;
    height: 504px;
    margin: 40px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
  }

  .storyLink {
    display: none;
  }
}

.carousel-box {
  width: 90vw;
  height: 504px;
  margin: 40px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
}

.storyLink {
  margin-top: 30;
  border-radius: 35;
  color: '#FFFFFF';
  border-color: '#FFFFFF';
  font-size: 16;
}

.topstories-img {
  width: 50%;
  height: 100%;
}

@media only screen and (max-width: 754px) {
  .topstories-img {
    width: 100%;
    height: 50%;
  }
}

.MuiCard-root {
  border-radius: 0px !important;
}
