.department-main-container {
  padding: 0px 4%;
  padding-top: 40px;
  background-color: #ffffff;

  .department-heading-container {
    text-align: center;
  }
}

.department-viewmore-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  .view-more-button {
    background-color: white;
    color: red;
    border-radius: 20px;
    width: 140px;
    border: solid 2px red;
  }
}

.resp-grid-card {
  // padding: 10px 0px;
  height: 64px;
  width: 394px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #d1d2d4;
  text-align: center;
}

.resp-grid-img-container {
  height: 290px;
  width: 394px;

  img {
    width: 100%;
    height: 100%;
  }
}

.hover-animation {
  filter: grayscale(80%);
}

.hover-animation :hover {
  filter: grayscale(0%);
  color: red;
}
.department-card-container {
  width: 40%;
  img {
    width: 100%;
    height: 100%;
  }
}
.department-grid-container {
  width: 30%;
}

.dept-grid-card {
  width: 100%;
  height: 360px;
  background-color: #d0d2d3;
  -webkit-filter: grayscale(100%) !important;
  filter: grayscale(100%) !important;
  color: #333333;
  text-align: center;
  transition: color 0.3s, background-color 0.3s;
}

.dept-grid-card:hover {
  -webkit-filter: grayscale(0) !important;
  filter: grayscale(0) !important;
  background-color: #333333;

  width: 300px;
  width: 100%;

  height: 360px;
  color: #ffffff;
  text-align: center;
}

.dept-grid-card img {
  -webkit-filter: grayscale(100%) !important;
  filter: grayscale(100%) !important;
  width: 300px;
  width: 100%;
  height: 300px;
}

.dept-grid-card img:hover {
  -webkit-filter: grayscale(0) !important;
  filter: grayscale(0) !important;
  width: 300px;
  width: 100%;
  height: 300px;
}

@media only screen and (max-width: 1000px) {
  .dept-grid-card-text {
    h3 {
      font-size: 20px;
    }
  }
}
@media only screen and (max-width: 430px) {
  .dept-grid-card img {
    -webkit-filter: grayscale(100%) !important;
    filter: grayscale(100%) !important;
    width: 300px;
    width: 100%;
    height: 116px;
  }

  .dept-grid-card img:hover {
    -webkit-filter: grayscale(0) !important;
    filter: grayscale(0) !important;
    width: 300px;
    width: 100%;
    height: 116px;
  }

  .dept-grid-card {
    height: 160px;
    overflow: hidden;
    .dept-grid-card-text {
      h3 {
        font-size: 14px;
      }
    }
  }

  .dept-grid-card:hover {
    // -webkit-filter: grayscale(0) !important;
    // filter: grayscale(0) !important;
    // background-color: #333333;
    // width: 100%;
    height: 160px;

    // color: #ffffff;
    text-align: center;
  }
}
