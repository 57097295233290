// import the sass files here

@import './variables';
@import './carousel';
@import './departmentGrid';
@import './instagram-feed';
@import './footer';

*:focus {
  outline: 1px dotted;
}

p {
  color: $primary-text-color;
}
.carousel {
  text-align: center;
}

/* max-width constrains the width of our carousel to 550, but shrinks on small devices */
.carousel__container {
  max-width: 550px;
  margin: auto;
}

/* gives us the illusion of a "centered" slide */
.carousel__slider {
  padding-left: 25%;
  padding-right: 25%;
}

/* gives us the illusion of spaces between the slides */
.carousel__inner-slide {
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  left: 5px;
  top: 5px;
  background-color: burlywood;
}

.mob-menu-open-body {
  overflow: hidden;
}

.image-gallery-thumbnail {
  width: 20% !important
}

.backToTopContainer {
  position: fixed;
  right: -100px;
  bottom: 50px;
  transition: right 0.5s;
  cursor: pointer;
  background-color: white;
  padding: 5px 8px;
  border-radius: 50px;
  border: 1px solid;
  color: #cc0000
}

.backToTopTransition {
  right: 15px;
}