@font-face {
	/* font-family: 'HelveticaforTarget';
	src: url("fonts/HelveticaforTarget.eot?#iefix");
	src: url("fonts/HelveticaforTarget.eot?#iefix") format("eot"), 
  url("fonts/HelveticaforTarget.woff") format("woff"), 
  url("fonts/HelveticaforTarget.ttf") format("truetype"),  */

  font-family: 'HelveticaforTarget';
  src: url('fonts/HelveticaforTarget.eot'); /* IE9 Compat Modes */
  src: url('fonts/HelveticaforTarget.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/HelveticaforTarget.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/HelveticaforTarget.woff') format('woff'), /* Pretty Modern Browsers */
       url('fonts/HelveticaforTarget.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('fonts/HelveticaforTarget.svg#svgHelveticaforTarget') format('svg'); /* Legacy iOS */


  font-family: 'HelveticaforTarget-bold';
  src: url('fonts/HelveticaforTarget-bold.eot'); /* IE9 Compat Modes */
  src: url('fonts/HelveticaforTarget-bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/HelveticaforTarget-bold.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/HelveticaforTarget-bold.woff') format('woff'), /* Pretty Modern Browsers */
       url('fonts/HelveticaforTarget-bold.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('fonts/HelveticaforTarget-bold.svg#svgHelveticaforTarget-bold') format('svg'); /* Legacy iOS */   
}




body {
  margin: 0;
  margin-top: 90px !important;
  font-family: 'HelveticaforTarget', Helvetica, Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff !important;
  font-size: 1rem !important;
}

a {
  color: #cc0000;
}

a:focus {
  outline: 1px dotted red !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.anchortag {
  color: #ffffff;
}
