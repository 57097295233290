.social-icons {
  height: 25px;
  margin-right: 5px;
  margin-left: 5px;
}

@media only screen and (max-width: 600px) {
  .footer-container {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
  }
}

@media only screen and (max-width: 754px) {
  .footerGridContainer {
    // background-color: red;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
  }
}
